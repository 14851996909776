import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {ApiService} from './api.service';
import {ProFormaModel} from '../models/proformaModel';
import {ExtendedProforma, IOnHold, IProformaSaveModel, ProForma, ProFormaDownload} from './data/proforma';
import {ListingProperty} from './data/listingPropety';
import { Preferences } from '../models/preferences';
import { PropertyStatusType } from './data/propertyStatusType';
import {ListingComps} from './data/listingComps';
import {ListingResult} from './data/listingResult';
import {PhotoItem} from './data/photoItem';
import {DateRangeFilter, DateRangeFormatFilter} from "./data/dateRangeFilter";
import { ITag } from './data/tags';
import { TagsUtils } from './tagUtils';
import { EVBUS_EVENTS_NAMES } from './update-request-service/model/updateRequest';
import {ITargetOption} from "../panels/proforma/proforma";
import {EntityUtils} from "./entityUtils";
import { IViewSetting, ViewMode } from '../ui/view-setting/viewSettingModel';
import * as underwriterUtils from '../utils/underwriterUtils';
import {GridRequestParams, UnderwriterAbstractService } from './underwriterAbstract.service';
import { BehaviorSubject } from 'rxjs';
import { PropertyGridComponent } from '../pages/propertylist/propertyGrid/propertyGrid.component';

export class CompsCache {
  query: string;
  comps: [];
}

@Injectable({
  providedIn: 'root'
})
export class UnderwriterService  extends UnderwriterAbstractService {
  private preferencesCache: Preferences;
  lastStatusBeforeHidden: PropertyStatusType;
  private coverageData: any;

  private propertyGridComponentSource = new BehaviorSubject<PropertyGridComponent | null>(null);
  propertyGridComponent$ = this.propertyGridComponentSource.asObservable();

  setPropertyGridComponent(propertyGridComponent$: PropertyGridComponent) {
    this.propertyGridComponentSource.next(propertyGridComponent$);
  }

  setCoverageData(data: any) {
    this.coverageData = data;
  }
  getCoverageCache() {

    return this.coverageData;
  }

  constructor(private apiService: ApiService) {
    super();
  }
  resetPreferences(): void {
    this.preferencesCache = null;
  }
  getPreferencesCache(gridName) {
    if (gridName === 'propertyGrid') {
      return this.preferencesCache.propertyListing;
    }
    return this.preferencesCache.bulkPropertyListing;
  }

  protected getAllListings(params: GridRequestParams): Promise<ListingResult> {
    let {
      selectedColumns,
      viewMode,
      buyBoxId,
      groupKeys,
      dateRange,
      viewSetting,
      fromLine,
      filters,
      sort,
      update,
      fetchTMUser,
      getCount,
      address,
      listResult
    } = params;

    return new Promise<ListingResult>((resolve, reject) => {
      const fromDate = dateRange
        ? {
          fromDate: dateRange.fromDate?.utc().format("YYYY-MM-DD HH:mm:ss") ?? '',
          toDate: dateRange.toDate?.utc().format("YYYY-MM-DD HH:mm:ss") ?? ''
        }
        : {fromDate: '', toDate: ''};

      this.apiService.getListings({
        selectedColumns,
        viewMode,
        buyBoxId,
        groupKeys,
        dateRange: fromDate,
        viewSetting,
        fromLine,
        filters,
        sort,
        update,
        fetchTMUser,
        getCount,
        address,
      }).then((result: ListingResult) => {

        const {count, hasMore, listings, activeTags} = result;
        TagsUtils.activeTagsSubject.next(activeTags);

        _.each(listings, (p: ListingProperty) => {
          underwriterUtils.initializeProperty(p);
        });

        listResult = listResult.concat(listings);
        resolve({listings: listResult, hasMore, count});
      }, (error) => {
        reject(error);
      });

    });
  }

  getActiveTags() {
    this.apiService.getActiveTags().then(tags => {
      TagsUtils.activeTagsSubject.next(tags);
    });
  }

  async getBulkPortfolioNames() {
    return await this.apiService.getBulkPortfolioNames();
  }

  getListings(params: Partial <GridRequestParams>): Promise<ListingResult> {
    let {
      selectedColumns,
      viewMode,
      buyBoxId,
      groupKeys,
      dateRange,
      viewSetting,
      fromLine,
      filters,
      sort,
      update,
      fetchTMUser,
      getCount,
      address
    } = params;

    return new Promise<ListingResult>((resolve, reject) => {

      this.getPreferences().then(() => {

        if (viewMode == ViewMode.MAESTRO) {
          if (!buyBoxId) {
            buyBoxId = this.preferencesCache.buyboxes.currentBuyBoxId;
          }

          let exist = _.find(this.preferencesCache.buyboxes.buyboxes, {'id': buyBoxId});
          if(!exist) {
            exist = _.find(this.preferencesCache.buyboxes.buyboxes, {'name': 'Default'});
            if(exist) {
              buyBoxId = exist.id;
            } else {
              buyBoxId = this.preferencesCache.buyboxes.buyboxes[0].id;
            }

            // Reset the current buybox preference
            this.preferencesCache.buyboxes.currentBuyBoxId = buyBoxId;
            this.savePreferences();
          }
        }

        const listResult: ListingProperty[] = [];

        this.getAllListings({
          selectedColumns,
          viewMode,
          buyBoxId,
          groupKeys,
          viewSetting,
          dateRange,
          fromLine,
          listResult,
          filters,
          sort,
          update,
          fetchTMUser,
          getCount,
          address,
        })
          .then(
            result => resolve(result),
            error => reject(error),
          );



        this.getPurchasingEntities().then(result => {
          const entities = [{ id: null, label: '-' }, ...(result || [])];

          EntityUtils.entitySubject.next(entities);
        });

      }, (error) => {
        reject(error);
      });
    });
  }

  reloadEntities () {
    this.getPurchasingEntities().then(result => {
      const entities = [{ id: null, label: '-' }, ...result];
      EntityUtils.entitySubject.next(entities);
    });
  }

  getListingProperty(aoListingId: any): Promise<ListingProperty> {
    return new Promise<ListingProperty>((resolve, reject) => {
      this.apiService.getListingProperty(aoListingId).then((p) => {
        underwriterUtils.initializeProperty(p);
        resolve(p);
      }, (error) => {
        reject(error);
      });
    });
  }


  getProforma(aoListingId: any, flowId?): Promise<ProFormaModel> {
    return new Promise<ProFormaModel>((resolve, reject) => {

      this.apiService.getProforma(aoListingId, flowId).then((proforma: ExtendedProforma) => {
        const model = new ProFormaModel();
        if (proforma) {
          model.sendToTransactionManager = proforma.sendToTransactionManager;
          model.pendingEvents = proforma.pendingEvents;
          model.workFlowNote = {
            type: null,
            newValue: proforma.workFlowNote,
            oldValue: proforma.workFlowNote
          };

          delete proforma.sendToTransactionManager;
          delete proforma.pendingEvents;
          model.proforma = proforma as ProForma;
          model.originalProforma = _.cloneDeep(proforma);
        } else {
          model.proforma = new ProForma();
          model.originalProforma = null;
          model.proforma.AOListingID = aoListingId;
        }

        resolve(model);

      }, (error) => {
        reject(error);
      });
    });
  }

  saveProforma(proformaModel: ProFormaModel, avmProformaModel: ProFormaModel, versioning: any, evBusEvents: EVBUS_EVENTS_NAMES[], lastUpdateDate, workflowState = ''): Promise< { onHold?: IOnHold, updatedProperty?: ListingProperty, proformaModel?: ProFormaModel } > {
    return new Promise<{ onHold?: IOnHold, updatedProperty?: ListingProperty, proformaModel?: ProFormaModel }>((resolve, reject) => {

      this.apiService.saveProforma(
        proformaModel.proforma,
        avmProformaModel.proforma,
        proformaModel.userInputProforma,
        proformaModel.messageOnSave,
        proformaModel.reasonFewComps,
        proformaModel.listingStatus,
        proformaModel.selectedComparableIds,
        proformaModel.listingData,
        versioning,
        evBusEvents,
        proformaModel.workFlowNote,
        proformaModel.sendToTransactionManager,
        proformaModel.pendingEvents,
        lastUpdateDate,
        workflowState
        ).then(async (saveResult: IProformaSaveModel) => {
          const onHold = saveResult.onHold;
          if(onHold || !_.isEmpty(onHold)) {
            resolve({
              onHold,
            });
            return;
          }
          const proforma = saveResult.proforma;
          proformaModel.isDirty = false;
          proformaModel.userInputProforma.id = proforma.id;
          proformaModel.sendToTransactionManager = proforma.sendToTransactionManager;
          proformaModel.pendingEvents = proforma.pendingEvents;
          proformaModel.workFlowNote = {
            type: proformaModel.workFlowNote.type,
            newValue: proforma.workFlowNote,
            oldValue: proforma.workFlowNote
          };
          delete proforma.sendToTransactionManager;
          delete proforma.pendingEvents;
          proformaModel.originalProforma = _.cloneDeep(proforma  as ProForma);
          proformaModel.proforma = proforma as ProForma;


          const p = await this.getListingProperty(proforma.AOListingID).catch(err => {
          reject(err);
        });

        if(p) {
          underwriterUtils.initializeProperty(p);
          proformaModel.listingStatus = p.underwritingStatus;

          resolve({
            updatedProperty: p,
            proformaModel: proformaModel
          });
        } else {
          resolve({
            updatedProperty: null,
            proformaModel: proformaModel
          });
        }

      }, (error) => {
        reject(error);
      });
    });
  }

  downloadProforma(aoListingId: any, date: any): Promise<ProFormaDownload> {
    return new Promise<ProFormaDownload>((resolve, reject) => {

      this.apiService.downloadProforma(aoListingId, date).then((data) => {
        resolve(data);
      }, (error) => {
        reject(error);
      });
    });
  }

  getPreferences(): Promise<Preferences> {
    return new Promise<Preferences>((resolve, reject) => {
      if (this.preferencesCache) {
        resolve(this.preferencesCache);
      } else {
        this.apiService.getPreferences().then((preferences: Preferences) => {
          this.preferencesCache = underwriterUtils.buildUnderwriterPreferences(preferences);
          resolve(preferences);
        }, (error) => {
          reject(error);
        });
      }
    });
  }

  savePreferences(newPreferences = null): Promise<Preferences> {
    return new Promise<Preferences>((resolve, reject) => {
      if (newPreferences) {
        this.preferencesCache = newPreferences;
      }

      this.apiService.savePreferences(this.preferencesCache).then((savedPreferences: Preferences) => {
        // this.preferencesCache = savedPreferences;

        resolve(this.preferencesCache);
      }, (error) => {
        reject(error);
      });

    });
  }


  hidePropertyListings(properties: ListingProperty[], message: string, forceUpdateOnConflict?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.apiService.hidePropertyListings(
        properties.map((r) => {
          return {AOListingID: r.AOListingID, StatusUpdateDate: r.StatusUpdateDate};
        }),
        message,
        forceUpdateOnConflict
        )
        .then((hideResult) => {
        if (hideResult.success) {
          _.each(properties, (p: ListingProperty) => {
            p.isUpdated = false;
            p.isHiddenForAll = true;
            p.underwritingStatus = PropertyStatusType.Hidden;
          });
        }

        resolve(hideResult);
      }, (error) => {
        reject(error);
      });
    });
  }

  updateFilters(listingName: string, filters: any): Promise<Preferences> {
    if(this.preferencesCache[listingName]) {
      this.preferencesCache[listingName].filters = filters;
    }
    return this.savePreferences();
  }

  savePropertyStatus(properties: ListingProperty[], forceUpdateOnConflict: boolean, status?: PropertyStatusType): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.apiService.savePropertyStatus(
        properties.map((r) => {
          return {
            AOListingID: r.AOListingID,
            StatusUpdateDate: r.StatusUpdateDate,
            status: status? status : r.status === PropertyStatusType.InvestorApprovalUpdated ?
                                    PropertyStatusType.InvestorApprovalReady : r.status === PropertyStatusType.PreInvestorApprovalUpdated ?
                                    PropertyStatusType.PreInvestorApprovalReady : PropertyStatusType.OfferReady
          };
        }),
        forceUpdateOnConflict
         ).then((updateResult) => {
        if(updateResult.success) {
          _.each(properties, (p: ListingProperty) => {
            p.isUpdated = false;
            p.underwritingStatus = p.status;
          });
        }

        resolve(updateResult);
      }, (error) => {
        reject(error);
      });
    });
  }

  saveProperty(property: ListingProperty, data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.apiService.saveProperty(property.AOListingID, data).then(() => {

        property.isUpdated = false;

        resolve(true);
      }, (error) => {
        reject(error);
      });
    });
  }

  getListingComparables(aoListingId: any, proformaId: any, filters: any): Promise<ListingComps[]> {
    return new Promise<ListingComps[]>((resolve, reject) => {
      const key: string = aoListingId.toString();

      this.apiService.getListingComparables(aoListingId, proformaId, filters).then((comps: ListingComps[]) => {
        const filteredComps = [];
        _.each(comps, (p: ListingComps, idx) => {
          if (typeof p == 'object') {
            p.BathsCount = p.FullBaths + (p.HalfBaths / 2);
            p.RealDaysOnMarket = underwriterUtils.calculateDaysOnMarket(p);

            if(p.RentSale == 'S' && (p.Status == 'A' || p.Status == 'P')) {
              p.LastLeasePrice = p.LastRent;
              p.LastLeaseDate = p.LastListingDate;

            } else if (p.RentSale == 'S' && p.Status == 'S' ) {
              p.LastLeasePrice = p.LastSalePrice;
              p.LastLeaseDate = p.LastSaleDate;
            } else  {
              p.LastLeasePrice = p.LastRent;
              p.LastLeaseDate = p.LastRentDate;
            }

            if (!p.Beds || !p.BathsCount || !p.LivingAreaSqFt || !p.YearBuilt) {
              p.missingDataTooltip = '<div class="a1-mask-icon alert-circle"></div>';

              p.missingData = `${
                (!p.Beds ? 'beds, ' : '') +
                (!p.BathsCount ? 'baths, ' : '') +
                (!p.LivingAreaSqFt ? 'sqft, ' : '') +
                (!p.YearBuilt ? 'year built, ' : '')
              }`.slice(0, -2);
              p.missingData = p.missingData ? `This property has missing data (${p.missingData})` : '';
            }

            if (p.proformaId) {
              p.selected = true;
            }
            filteredComps.push(p);
          }
        });

        resolve(filteredComps);
      }, (error) => {
        reject(error);
      });
    });
  }

  getComparablePhotos(aoListingId: any, aoCompId: any, aoPhotoGroupId: any): Promise<any[]> {
    return new Promise<ListingComps[]>((resolve, reject) => {
      this.apiService.getComparablePhotos(aoListingId, aoCompId, aoPhotoGroupId).then((compPhotos: any[]) => {
        resolve(compPhotos);
      }, (error) => {
        reject(error);
      });
    });
  }

  getListingPropertyPhotos(aoListingId: any): Promise<PhotoItem[]> {
    return new Promise<PhotoItem[]>((resolve, reject) => {
      this.apiService.getListingPropertyPhotos(aoListingId).then((compPhotos: any[]) => {
        resolve(compPhotos);
      }, (error) => {
        reject(error);
      });
    });
  }

  getTagsList(): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.apiService.getTags().then((tagList) => {

        resolve(tagList);
      }, (error) => {
        reject(error);
      });
    });
  }

  updateTags(tagData: ITag): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      this.apiService.updateTags(tagData).then((commonUpdateDate) => {
        resolve(commonUpdateDate);
      }, (error) => {
        reject(error);
      });
    });
  }

  getPurchasingEntities() {
    return new Promise<ITargetOption[]>((resolve, reject) => {
      this.apiService.getPurchasingEntities()
        .then((result: ITargetOption[]) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });

    });
  }

  refreshEnteties() {
    return new Promise (async (resolve, reject) => {
      try {
        if (!EntityUtils.entitiesInitialized) {
          this.getPurchasingEntities().then((entities) => {
            EntityUtils.entitySubject.next(entities);
            EntityUtils.entitiesInitialized = true;

            if (!EntityUtils.startInitialized) {
              EntityUtils.startInitialized = true;
              setInterval(()=> {
                this.getPurchasingEntities().then((ett) => {
                  EntityUtils.entitySubject.next(ett);
                });
              }, 12000);
            }
            resolve(null);
          });
        } else {
          resolve(null);
        }
      } catch (e) {
        if (EntityUtils.entitySubject.value && EntityUtils.entitySubject.value.length) {
          resolve(null);
        } else {
          reject(e);
        }
      }
    });
  }

  async queryUQAPI( AOListingID: string, purchasingEntity: string, region: string, UQClassification: string) {
    let UQResponse = await this.apiService.getNetYield(AOListingID, {
      purchasingEntity,
      region,
      UQClassification
    });
    if (Object.keys(UQResponse).length) {
      UQResponse.minYieldRate = UQResponse.minYieldRate / 100;
      UQResponse.minCapRate = UQResponse.minCapRate / 100;
      UQResponse.renovationFeePercent = UQResponse.renovationFeePercent / 100;
    }
    return UQResponse
  }

  getCoverageData(zipCode= null) {
    return this.apiService.getCoverageData(zipCode);
  }
}
